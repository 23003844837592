import React from "react";
import theme from "theme";
import { Theme, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacy"} />
		<Helmet>
			<title>
				EventExcellence
			</title>
			<meta name={"description"} content={"Güvenilir Etkinlik Yönetimi Ortağınız"} />
			<meta property={"og:title"} content={"EventExcellence"} />
			<meta property={"og:description"} content={"Güvenilir Etkinlik Yönetimi Ortağınız"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66ab51bbe5d277001896bb59/images/4-4.jpg?v=2024-08-01T10:25:49.524Z"} />
		</Helmet>
		<Components.Header1>
			<Override slot="link1">
				Şartlar ve Koşullar
			</Override>
		</Components.Header1>
		<Section padding="80px 0 0 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h2" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Gizlilik Politikası
			</Text>
			<Text as="p" font="--lead" margin="20px 0 20px 0" text-align="center">
				Bu Gizlilik Politikası, EventExcellence'in Türkiye'deki müşterilerinden topladığı bilgilerin nasıl kullanıldığını, saklandığını ve korunduğunu açıklamaktadır. Hizmetlerimizi kullanmadan önce lütfen bu politikayı dikkatlice okuyun.
			</Text>
		</Section>
		<Section padding="40px 0" sm-padding="20px 0">
			<Override slot="SectionContent" align-items="flex-start" />
			<Text as="h3" font="--headline3" margin="20px 0">
				1. Toplanan Bilgiler
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				EventExcellence, hizmetlerini sunabilmek için müşterilerinden belirli kişisel bilgileri toplayabilir. Bu bilgiler arasında ad, e-posta adresi, telefon numarası, etkinlik detayları gibi bilgiler yer alabilir. Ayrıca, web sitemizi ziyaret ettiğinizde çerezler ve benzeri teknolojiler aracılığıyla otomatik olarak bilgi toplayabiliriz.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				2. Bilgilerin Kullanımı
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Topladığımız bilgileri, hizmetlerimizi sunmak, müşteri taleplerini karşılamak ve etkinliklerinizin başarıyla gerçekleşmesini sağlamak için kullanırız. Ayrıca, sizinle iletişime geçmek, etkinlik detaylarını netleştirmek ve müşteri memnuniyetini artırmak için bu bilgileri kullanabiliriz.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				3. Bilgilerin Paylaşımı
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				EventExcellence, müşterilerinin kişisel bilgilerini üçüncü şahıslarla paylaşmamayı taahhüt eder. Ancak, hizmetlerin yerine getirilmesi için gerekli olduğunda, bu bilgileri yalnızca güvenilir iş ortaklarımızla paylaşabiliriz. Bu paylaşım, yalnızca ilgili hizmetlerin sağlanması amacıyla ve gerekli olduğu ölçüde yapılır.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				4. Bilgilerin Saklanması ve Korunması
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				EventExcellence, topladığı kişisel bilgileri güvenli bir şekilde saklamak için uygun güvenlik önlemleri alır. Bilgilerinizin yetkisiz erişime, ifşaya, değiştirilmesine veya imha edilmesine karşı korunması için endüstri standartlarında güvenlik tedbirleri uygulanmaktadır.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				5. Çerezler ve Diğer İzleme Teknolojileri
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Web sitemizi ziyaret ettiğinizde, deneyiminizi geliştirmek ve site kullanımınızı analiz etmek için çerezler ve diğer izleme teknolojileri kullanabiliriz. Bu teknolojiler, tarayıcınıza küçük veri dosyaları yerleştirir ve tercihlerinizi hatırlamamıza yardımcı olur. Çerezleri devre dışı bırakmayı tercih edebilirsiniz, ancak bu durumda web sitemizin bazı özelliklerinden tam olarak yararlanamayabilirsiniz.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				6. Gizlilik Politikası Değişiklikleri
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				EventExcellence, gizlilik politikasını zaman zaman güncelleyebilir. Bu politikadaki değişiklikler, güncellenmiş politikanın web sitemizde yayınlanmasıyla geçerli olacaktır. Önemli değişiklikler yapıldığında, sizi e-posta yoluyla bilgilendirebiliriz.
			</Text>
			<Text as="h3" font="--headline3" margin="20px 0">
				7. İletişim Bilgileri
			</Text>
			<Text as="p" font="--base" margin="10px 0">
				Bu Gizlilik Politikası hakkında sorularınız veya endişeleriniz varsa, lütfen info@eventexcellence.com adresinden bizimle iletişime geçin. Gizliliğiniz bizim için önemlidir ve bu konudaki her türlü sorunuza yanıt vermekten memnuniyet duyarız.
			</Text>
		</Section>
		<Components.Contact />
		<Components.NewFooter1 />
		<Components.Terms />
	</Theme>;
});